import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LanguageIcon from '@mui/icons-material/Language';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ForumIcon from '@mui/icons-material/Forum';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import KioskButton from '../theme/Button';
import LanguageSelectionDialog from '../components/LanguageSelection';
import Footer from '../components/Footer';
import { useStateValue } from '../store';

import logo from '../logo.svg';
import './index.css';

function App() {
    const [openDialog, setOpenDialog] = useState(false);
    const [{ languages }] = useStateValue();

    // dropdown language upper right
    const languageSelected = languages.data.find((lng) => lng.value === languages.defaultLanguage);

    return (
        <div className="App">
            <header className="App-header">
                <div className="Header-language-option">
                    <Button
                        sx={{ color: 'white' }}
                        startIcon={<img src={languageSelected.img} width={20} alt="bandera" />}
                        endIcon={<ArrowDropDownIcon />}
                        onClick={() => setOpenDialog(true)}
                    >
                        {languageSelected.label}
                    </Button>
                </div>
                <div className="Header-logo">
                    <img src={logo} className="App-logo" alt="logo" />
                </div>
            </header>
            <main className="App-main">
                <h1>How can we help you today?</h1>
                <div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#F67C1E"
                            size="large"
                            startIcon={<MedicalServicesIcon />}
                            component={RouterLink}
                            to="/health-wellness"
                        >
                            Health and Welness Check
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#1ECBF6"
                            size="large"
                            startIcon={<TrendingUpIcon />}
                        >
                            DEN Customer Service Survey
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#1CC46A"
                            size="large"
                            startIcon={<LocalHospitalIcon />}
                        >
                            Emergency Access Services
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#E652C3"
                            size="large"
                            startIcon={<LanguageIcon />}
                            component={RouterLink}
                            to="/free-nation"
                        >
                            Free Nationwide and International Calling
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#8F8F8F"
                            size="large"
                            startIcon={<HeadsetMicIcon />}
                        >
                            DEN Live Video Agent
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#F6BD1E"
                            size="large"
                            startIcon={<ForumIcon />}
                        >
                            Multilingual Chat Room
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#2EB777"
                            size="large"
                            startIcon={<ShoppingCartIcon />}
                        >
                            DEN Online Marketplace
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#C72929"
                            size="large"
                            startIcon={<QrCode2Icon />}
                        >
                            QR Code Accesible Creator
                        </KioskButton>
                    </div>
                    <div className="Button-wrapper">
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#A71EF6"
                            size="large"
                            startIcon={<HearingDisabledIcon />}
                        >
                            TTY/TDD is for video relay services for the deaf and hearing impaired
                        </KioskButton>
                    </div>
                </div>
            </main>
            <Footer />
            <LanguageSelectionDialog open={openDialog} handleOpen={setOpenDialog} />
        </div>
    );
}

export default App;
