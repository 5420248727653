import * as React from 'react';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import HealthWellness from './views/HealthWellness';
import FreeNationWide from './views/FreeNationWide';
import PhoneFreeNation from './views/PhoneFreeNation';
import theme from './theme';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import IndexApp from './views/index';
import { StateProvider } from './store';
import { sortLanguages } from './utils/languages';

function App() {
    const [state, setState] = React.useState(null);
    React.useEffect(() => {
        async function fetchData() {
            const { data: languages } = await axios.get(
                'https://core.worldwidetechconnections.com/languages',
            );
            // sort languages
            const sortedLanguages = sortLanguages(languages);
            const languagesMapped = sortedLanguages.map((item, index) => ({
                id: index,
                label: item.name,
                value: item.code,
                img: item.flag,
                services: item.services,
            }));
            setState(languagesMapped);
        }
        fetchData();
    }, []);

    if (!state) return null;
    return (
        <StateProvider languagesData={state}>
            <ThemeProvider theme={theme}>
                <React.StrictMode>
                    <BrowserRouter>
                        <Routes>
                            <Route index path="/" element={<IndexApp />} />
                            <Route path="/free-nation" element={<FreeNationWide />} />
                            <Route path="/phone-free-nation" element={<PhoneFreeNation />} />
                            <Route path="/health-wellness" element={<HealthWellness />} />
                        </Routes>
                    </BrowserRouter>
                </React.StrictMode>
            </ThemeProvider>
        </StateProvider>
    );
}

export default App;
