import logo from '../logo.svg';

function Header() {
    return (
        <header className="App-header">
            <div className="Header-logo">
                <img src={logo} className="App-logo" alt="logo" />
            </div>
        </header>
    );
}

export default Header;
