import Box from '@mui/material/Box';
import logo from '../assets/logo-footer.png';

function Footer() {
    return (
        <footer>
            <Box
                className="App-footer"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
            >
                <p>Powered by</p>&nbsp;&nbsp;
                <img src={logo} className="App-logo" alt="logo" />
            </Box>
        </footer>
    );
}

export default Footer;
