import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import { useStateValue } from '../store';

function SimpleDialog(props) {
    const { onClose, onChange, languages, open } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onChange(value);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <Box
                sx={{
                    width: 'calc(100% - 80px)',
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 20,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <DialogTitle>Select a language</DialogTitle>
                <IconButton aria-label="delete" onClick={onClose}>
                    <CancelIcon />
                </IconButton>
            </Box>
            <List sx={{ pt: 0, top: 60 }}>
                {languages.map((language) => (
                    <ListItem
                        button
                        onClick={() => handleListItemClick(language.value)}
                        key={language.id}
                    >
                        <ListItemAvatar>
                            <Avatar src={language.img} />
                        </ListItemAvatar>
                        <ListItemText primary={language.label} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function LanguageSelectionDialog({ open, handleOpen }) {
    const [{ languages }, dispatch] = useStateValue();

    const handleChange = (value) => {
        handleOpen(false);
        dispatch({
            type: 'SET_LANGUAGE',
            payload: value,
        });
    };

    const handleClose = (value) => {
        handleOpen(false);
    };

    return (
        <SimpleDialog
            selectedValue={languages.defaultLanguage}
            open={open}
            onClose={handleClose}
            onChange={handleChange}
            languages={languages.data}
        />
    );
}
